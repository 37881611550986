






































































import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { SummaryViewModel } from '../../viewmodel/summary-viewmodel'

@Observer
@Component
export default class StakingInformation extends Vue {
  @Inject() vm!: SummaryViewModel
  walletStore = walletStore
  gradientBanner = ['text--base', 'text--base', 'text--lighten-1', 'text--lighten-2', 'text--lighten-3']
}
